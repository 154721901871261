import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/globals.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@mantine/charts/styles.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@mantine/core/styles.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/layout/DefaultLayout.tsx");
